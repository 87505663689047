import React from 'react';
import Loading from './Loading';
import Meta from './Meta';

export default function DescriptionBlock(props){
    
    const meta = props.meta;
    return(
    <article className="content-element description-block">
        <h3>{props.title ? props.title : <Loading />}</h3>
        <Meta meta={meta} />
        {props.children}
    </article>
    );
}