import React from 'react';
import { useStaticQuery, graphql } from "gatsby"
import Loading from '../components/Loading';
import ContentSection from '../components/ContentSection';
import Layout from '../components/Layout';
import YoutubeVideo from '../components/YoutubeVideo';

export default function VideosPage(){
    let data = useStaticQuery(graphql`
    query AllVideosQuery {
      allMarkdownRemark(filter: {fileAbsolutePath: {regex: "/videos/"}}) {
        edges {
          node {
            html
            frontmatter {
              videos {
                title
                URL
                meta {
                  person
                  duration
                  time
                  location
                }
              }
            }
          }
        }
      }
    }

    `);
    data = data.allMarkdownRemark.edges
    return(
        
    <Layout title="Wideo">
        {!data ? <Loading /> : data.map((video,i)=>{
          const {title, meta, URL} = video.node.frontmatter.videos
                return <ContentSection key={i} 
                title={title} 
                meta={meta} 
                description={
                  <div dangerouslySetInnerHTML={{ __html: video.node.html }} className="description-content"></div> 
                }
                >
                  <YoutubeVideo URL={URL} title={title}/>
                </ContentSection>
          })}
    </Layout>
    );
}