import React from 'react';
import InfoIcon from './InfoIcon';


export default function Meta(props){
    
    const meta = props.meta;
    return(
    <>
    {!meta ? "" : Object.keys(meta).map((el, i)=>(meta[el] ? <InfoIcon name={el} key={i} divClassName="info-icon">{meta[el]}</InfoIcon> : ""))}
    </>
    );
}