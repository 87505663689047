import React from 'react';
import DescriptionBlock from './DescriptionBlock';

export default function ContentSection(props){
    return(
    <section className="content-section">
        <DescriptionBlock title={props.title} meta={props.meta}>
            {props.description}
        </DescriptionBlock>
        {props.children}
    </section>
    );
}