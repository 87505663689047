import React from 'react';
import Icon from './Icon';

export default function InfoIcon(props){
    return(
        <div className={props.divClassName}>
        <Icon name={props.name} alt={props.name} type="icon-sm"/>
        <span className="thin alt">{props.children}</span>
        </div>
    );
}